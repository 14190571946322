<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Name')" v-model="bookMagazine.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('State') }}</label>
                    <div class="control">
                        <input type="checkbox" v-model="isActive" @click="toggleCheckboxes">
                        {{ i18n(isActive ? 'Active' : 'Inactive') }}
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Select Program') }}</label>
                    <vue-select :source="route('programs.options')"
                                v-model="bookMagazine.program"
                                :http="http"
                                track-by="name"
                                :placeholder="i18n('Pick an option')"/>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Property') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('UserProperty')"
                               v-model="bookMagazine.userProperty">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Segmentation') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Segmentation')"
                               v-model="bookMagazine.segmentation">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Position') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('Position')" v-model="bookMagazine.position">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input" type="file"
                                   accept=".pdf" @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                        <p class="letterFileSize">
                            {{ i18n('Maximum file size') }}: 18 MB.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchSave">
            <button class="button butt-save">
                {{ i18n('Save') }}
                <fa class="i-icon" icon="check"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { VueSelect } from '@enso-ui/select/bulma';

export default {
    name: 'Create',

    components: { Fa, VueSelect },

    inject: ['http', 'i18n', 'errorHandler', 'route', 'errors'],

    data: () => ({
        bookMagazine: {},
        isActive: false,
        programs: {},
        fileName: '',
    }),

    methods: {
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.bookMagazine.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },

        fetchSave() {
            const formData = new FormData();
            formData.append('file', this.bookMagazine.file);
            formData.append('name', this.bookMagazine.name);
            formData.append('state', this.isActive ? 'Active' : 'Inactive');
            formData.append('program', this.bookMagazine.program);
            formData.append('userProperty', this.bookMagazine.userProperty);
            formData.append('segmentation', this.bookMagazine.segmentation);
            formData.append('position', this.bookMagazine.position);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('bookMagazine.store'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/bookMagazine');
                }).catch(this.errorHandler);
        },

        toggleCheckboxes() {
            this.isActive = !this.bookMagazine.state === 'Active';
            this.bookMagazine.state = !this.bookMagazine.state === 'Active' ? 'Active' : 'Inactive';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.butt-save{
    background-color: $green;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-top: 33px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

.letterFileSize{
    font-size: small;
    color: $greyToneEleven;
}

</style>
